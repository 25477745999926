<template>
    <div role="button">
        <button v-if="position === 'side'"
            type="button"
            :aria-label="direction === 'left' ? tg('aria.previous-slide') : tg('aria.next-slide')"
            class="group absolute bottom-2 z-10 hidden items-center text-lg lg:inset-y-0 lg:flex lg:text-3xl"
            :class="{
                'left-2': direction === 'left',
                'right-2 ': direction === 'right',
            }">
            <span class="flex size-12 items-center justify-center rounded-full bg-black text-white
                group-hover:bg-gray-900 group-focus:bg-gray-900 group-active:bg-gray-800">
                <v-icon v-if="direction === 'left'"
                    icon="chevron-left" />

                <v-icon v-else
                    icon="chevron-right" />
            </span>
        </button>

        <button v-if="position === 'bottom'"
            class="group relative bottom-2 z-10 items-center text-lg lg:inset-y-0 lg:flex lg:text-3xl"
            :aria-label="direction === 'left' ? tg('aria.previous-slide') : tg('aria.next-slide')"
            type="button">
            <span class="flex size-12 items-center justify-center rounded-full bg-black text-white
                group-hover:bg-gray-900 group-focus:bg-gray-900 group-active:bg-gray-800">
                <v-icon v-if="direction === 'left'"
                    icon="chevron-left" />

                <v-icon v-else
                    icon="chevron-right" />
            </span>
        </button>
    </div>
</template>

<script lang="ts" setup>
defineProps<{
    direction: 'left' | 'right'
    position: 'side' | 'bottom'
}>();

const { t: tg } = useI18n({
    useScope: 'global',
});
</script>
